import React from 'react'
import {Logo} from './Logo'



const About = () => {


return(
  <>
  <div className='desktop_navbar'>
</div>
  <Logo/>
  <div className='wrapper-div'>
<div style={{display:'flex',width:'90vw', flexDirection:'column' as 'column', alignItems:'center', marginTop:'5rem', textAlign:'center'}}>
  <p style={{display:'flex', color:'#4abd55', fontSize:'calc(13px + 0.5vw)'}}>About Grapple</p>
  <span className='marg-1'>Grapple.cc is a new kind of social network. For the first time, users don't need to give away their data to any kind of middleman.</span>
   <span className='marg-1'>Grapple is based on a 1:N sharing model, which means everything users share is being sent to all members. In addition, whenever a new peer joins the room, former members will automatically send all the content to the newcomer. This way, in theory, if one could maintain two browser tabs that never close, the content stays in the room forever.</span>
  <span className='marg-1'>This is only possible because of technology called WebRTC.</span>
  <span className='marg-1'>WebRTC utilizes signalling servers to authenticate and pair users when they connect. Signalling servers can not see the data transferred throughout the session. From there, clients can communicate directly, through an encrypted socket. When the connection is closed, nothing remains on the network.</span>
 
  <span className='marg-1'>Our aim is to make internet communication more open, self hosted and decentralized. There was a time when internet was a worldwide network of many small subjects providing a wide range of services and content and we want it back, this time with more powerful and secure technology. </span>
  <span className='marg-1' style={{color:'#4abd55', fontSize:'18px'}}>Because internet owned by a handful of companies is just boring... </span>

  <div style={{display:'flex',flexDirection:'column' as 'column', width:'90vw', textAlign:'center'}}>
  <p style={{display:'flex', fontSize:'12px', justifyContent:'center',lineHeight:'200%', color:'#d43333',   animation: 'slow 2s ease forwards'}}>DISCLAIMER:</p>

  <p style={{display:'flex', fontSize:'calc(12px + 0.3vw)',marginTop:'-10px', justifyContent:'center',lineHeight:'200%', color:'#d43333',   animation: 'slow 2s ease forwards'}}>This is an online file sharing tool. Use at your own risk. We, the creators have no way of moderating or mostly even seeing what's happening in the room, since everything happens directly, browser to browser. At the moment, there is no database or search engine keeping track of what's happening in the rooms. The maximum amount of data shareable in a room depends on clients' computers power. There are some known issues with multiple older mobile browsers. This site was primarily designed for computers, though it should work on most modern mobile devices.</p>
      <p style={{display:'flex', fontSize:'calc(12px + 0.3vw)',marginTop:'-10px', justifyContent:'center',lineHeight:'200%', color:'#d43333',   animation: 'slow 2s ease forwards'}}>If you found a bug, feel free to write about it in the 'buglist' room, alternaively, write an e-mail to 'exitbotnet@proton.me'</p>
      </div>

  <div style={{display:'flex', marginTop:'30px', color:'white',maxWidth:'60vw', flexDirection:'column' as 'column', alignItems:'center', gap:'1rem'}}>
  <p style={{display:'flex', fontSize:'calc(12px + 0.5vw)', justifyContent:'center', color:'white', maxWidth:'54vw'}}>Donate</p>
  <span style={{display:'flex', fontSize:'calc(11px + 0.5vw)', color:'white', maxWidth:'65vw', justifyContent:'center'}}>This site does not collect any data to be sold.</span>
  <p style={{display:'flex', fontSize:'calc(11px + 0.5vw)', justifyContent:'center', color:'white', maxWidth:'54vw'}}>That's why we need our donators in order to stay online.</p>

<div className='Card'>
    <span style={{display:'flex'}}>BTC:</span>
    <p className='coin-address'>bc1qh5ak4xrcyyusul7j95ftxfugggl2dq2l004k7g</p>
    <button className='donatebutton'  onClick={() => {navigator.clipboard.writeText("bc1qh5ak4xrcyyusul7j95ftxfugggl2dq2l004k7g")}}>
    Copy
    </button>
      </div>


    <div className='Card'>
    <span style={{display:'flex'}}>ETH:</span>
    <p className='coin-address'>0x657d1250e55628B71c23C15ec7d0aB4638D8D01C</p>
    <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("0x657d1250e55628B71c23C15ec7d0aB4638D8D01C")}}>
    Copy
    </button>
      </div>


      <div className='Card'>
      <span style={{display:'flex'}}>BNB:</span>
      <p className='coin-address'>0x657d1250e55628B71c23C15ec7d0aB4638D8D01C</p>
      <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("0x657d1250e55628B71c23C15ec7d0aB4638D8D01C")}}>
      Copy
      </button>
        </div>

<div className='Card'>
        <span style={{display:'flex'}}>LTC:</span>
        <p className='coin-address'>ltc1qvj2f6setz53m60x6szfx68vaw58773wd6r2rxw</p>
        <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("ltc1qvj2f6setz53m60x6szfx68vaw58773wd6r2rxw")}}>
        Copy
        </button>
          </div>

<div className='Card'>
          <span style={{display:'flex'}}>DOGE:</span>
          <p className='coin-address'>DA6wpabrFjRvaCfSABFDD3xVLiNk5REDD8</p>
          <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("DA6wpabrFjRvaCfSABFDD3xVLiNk5REDD8")}}>
          Copy
          </button>
            </div>
<div className='Card'>
            <span style={{display:'flex'}}>MATIC:</span>
            <p className='coin-address'>0xA560C0ba8F6973Cfc88f5915c2d7b11964fF50b9</p>
            <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("0xA560C0ba8F6973Cfc88f5915c2d7b11964fF50b9")}}>
            Copy
            </button>
              </div>
<div className='Card'>
              <span style={{display:'flex'}}>XRP:</span>
              <p className='coin-address'>rpR3AzT6fyNwpoLyd12y8gpyjttdRwMsUu</p>
              <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("rpR3AzT6fyNwpoLyd12y8gpyjttdRwMsUu")}}>
              Copy
              </button>
                </div>
<div className='Card'>
                <span style={{display:'flex'}}>XMR:</span>
                <p className='coin-address'>43mS931tDLzipUomQ411uaQQv2CrQw2MN9cboa68rJqXgGCBuNyXJr49VFKb5EnRRdAHokiNMFVJSb6T2Bs66hD9F3k4Tat</p>
                <button className='donatebutton' onClick={() => {navigator.clipboard.writeText("43mS931tDLzipUomQ411uaQQv2CrQw2MN9cboa68rJqXgGCBuNyXJr49VFKb5EnRRdAHokiNMFVJSb6T2Bs66hD9F3k4Tat")}}>
                Copy
                </button>
                  </div>

              <p style={{display:'flex', justifyContent:'center', color:'white', maxWidth:'54vw', marginTop:'2rem'}}>Or just check out some of our partnered sites...</p>
              <div className='Card'>
              <a href='https://simpleswap.io/?ref=a45073f61eae' style={{display:'flex', fontFamily:'Press Start 2P',marginBottom:'0.5rem',color:'lightblue', fontSize:'0.8rem'}}>SimpleSwap.io</a>
              <span>Exchange 500+ crypto coins and tokens without signing up. </span>
              <span>Low exchange fees, instant payments.</span>

              </div>
              <div className='Card'>
              <a href='https://freebitco.in/?r=46961039' style={{display:'flex', fontFamily:'Press Start 2P',marginBottom:'0.5rem', color:'lightblue', fontSize:'0.8rem'}}>Freebitco.in</a>
              <span>Earn up to $200 worth of BTC every hour. </span>
              <span>Play dice, participate in weekly lottery and contests.</span>
              <span>Verify e-mail and get a daily free spin in Wheel Of Fortune. </span>
              <span>No deposit required. </span>

              </div>
              <div className='Card'>
              <a href='https://rollercoin.com/?r=lauc8pdv' style={{display:'flex', fontFamily:'Press Start 2P',marginBottom:'0.5rem',color:'lightblue', fontSize:'0.8rem'}}>Rollercoin.com</a>
              <span>Online mining simulator where you earn real crypto just for playing games. </span>
              <span>Competitive and fun. Free to play.</span>

              </div>
  </div>
</div>
</div>
  </>
)
}
export default About

//// import {Spinner} from 'baseui/spinner';
//
//
// export const LoadingSpinner = withStyle(Spinner, {
//   width: '1.5vw',
//   height: '1.5vw',
//   borderLeftWidth: '4px',
//   borderRightWidth: '4px',
//   borderTopWidth: '4px',
//   borderBottomWidth: '4px',
//   borderTopColor: 'lightgreen',
// });



export const topstatusbar ={
padding: '0px',
display: 'flex',


flexDirection: 'row' as 'row',
alignItems:'right',
justifyContent: 'right'


}


export const posttextstyle = {
display:'flex',
maxWidth: '100vw',
height: '100px',
position: 'fixed' as 'fixed',
bottom: '10px',
//right: '20px',
zIndex:'3',
gap: '1vw',
alignItems: 'center'
}

export const settingstyle = {
display:'flex',
maxWidth: '100vw',
position: 'fixed' as 'fixed',
top: '110px',
left: '0px',
right: '0px',
gap: '1vw',
justifyContent:'center'
}




export const postswindow = {
display: 'flex',
//position: 'fixed' as 'fixed',
minHeight:'100vh',
marginTop: '4.2vw',
maxWidth: '40em',
top: '50px',
alignItems: 'center',
justifyContent: 'center',
flexDirection: 'column' as 'column',
gap: "1rem",

}

export const singlepoststyle = {
display: 'flex',
width: '90%',
alignItems:'center',
justifyContent:'center',
margin: '3rem',


}

export const textareaStyle = {display:'flex', maxWidth:'90vw', backgroundColor:'rgba(0,10,20,0.8)', color:'white', borderRadius:'5px', resize:'none' as 'none', borderColor:'gray'}

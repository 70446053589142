import React from 'react'

import {Logo} from './Logo'

const DeclineInfo = () => {


return(
  <>

<div className='wrapper-div'>
<div className='desktop_navbar'>
<Logo/>
</div>
  <div className='Card' style={{display:'flex',marginTop:'5em' ,width:'95vw',backgroundColor: 'rgba(203, 23, 22, 0.5)',textAlign:'center', justifyContent:'center', alignItems:'center', borderStyle:'solid', flexDirection:'column' as 'column'}}>
  <p style={{display:'flex', color:'white'}}>DECLINE</p>
  <span style={{display:'flex', color:'white'}}>Users can vote to remove - decline posts. For a post to be deleted, at least 80% of current room members must press the post's Decline button. </span>
  <span style={{display:'flex', color:'white', margin:'1vw'}}>Declined posts stay in the pool for a short time after reaching the 80% threshold. For example, if one of the declining peers disconnects, the post will be recovered. This only works once per post. After that, it will be irreversibly deleted.</span>
  <span style={{display:'flex', color:'white', margin:'1vw'}}>Your decline votes vanish every time you refresh the page, since your peer identity changes on every reload.</span>

</div>
</div>
  </>
)
}
export default DeclineInfo



 const HomeScreen = () => {

  return(
<div style={{marginTop:'4em'}}>


<div className='more-info' >


              <div className='feature' >
<div className='Card' style={{backgroundColor:'rgba(0,35,0,0.1)'}}>
                <div className='content'>


                 <h2 style={{display:'flex',alignSelf:'center',color:'#31C48D', marginBottom:'1em', fontSize:'1.3em', lineHeight:'1.5em'}} className='title'>Anonymous and decentralized Chatroom</h2>


                   <p className='desc'>Write messages, share photos.</p>
   <p className='desc'>Comments and votes</p>
                <p className='desc'>Karma-based rating system</p>

                <p className='desc'>Self-moderation using Decline votes</p>



</div>
                  <a className='Card highlightbutton' style={{display:'flex',color:'#31C48D'}} href='https://peerbox.cc/about'> About Grapple</a>

                </div>
<div className='feature-left'>
<div className='Card' style={{backgroundColor:'rgba(35,0,0,0.1)'}}>
                 <h2 style={{display:'flex',alignSelf:'center',color:'rgba(250,20,50,1)', marginBottom:'1em', fontSize:'1.3em', lineHeight:'1.5em'}} className='title'>Own your data</h2>
 <p className='desc'>Fully Peer-to-Peer</p>
      <p className='desc'>No signup, no e-mail, no nonsense.</p>
           

 

               <p className='desc'>Your Browser = Your Server</p>
                 <a className='Card declinebutton' style={{display:'flex', marginTop:'1em',color:'rgba(250,20,50,1)'}} href='https://peerbox.cc/howto'> How does it work?</a>

          </div>
</div>
 
</div>
</div>
</div>
)

}
export default HomeScreen

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Howto from './howto'
import App from './App';
import About from './about'
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route , Routes} from "react-router-dom";
import DeclineInfo from './decline'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
    <Router basename="/">
    <Routes>
    <Route path='/room/:roomId' element={<App />}/>
    <Route path='/' element={<App />}/>
    <Route path='/howto/decline' element={<DeclineInfo />}/>
    <Route path='/about' element={<About />}/>
    <Route path='/howto' element={<Howto />}/>
    </Routes>
    </Router>




      </CookiesProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

    import React from 'react'



    const Howto = () => {


    return(
      <>

      <div style={{position:'absolute',display: 'flex',zIndex:'2', left: '15px', top:'0.5vw'}}>
        <button style={{display:'flex', backgroundColor:'rgba(0,10,0)',borderRadius:'10%',borderColor:'lightgreen', justifyContent:'center', alignItems:'center', width:'max-content'}} onClick={() => {window.location.href='https://peerbox.cc'}} >
        <span style={{display:'flex', margin:'0vw', color:'#4abd55', fontSize:'calc(9px + 1.2vw)',letterSpacing:'0vw'}}>Peer</span><span style={{display:'flex', margin:'0vw', color:'rgba(200,20,20,0.9)', fontSize:'calc(9px + 1.2vw)',letterSpacing:'0vw'}}>Box</span><span style={{display:'flex', margin:'0vw', color:'rgba(0,180,200,0.9)', fontSize:'0.5vw',letterSpacing:'0vw'}}>beta</span>


        </button>
        </div>
    <div style={{display:'flex',width:'100vw', flexDirection:'column' as 'column', alignItems:'center', marginTop:'5rem'}}>
      <div style={{display:'flex', width:'95vw',backgroundColor: 'rgba(22, 153, 22, 0.1)',textAlign:'center', justifyContent:'center', alignItems:'center', borderColor:'white', borderStyle:'solid', flexDirection:'column' as 'column'}}>
      <p style={{display:'flex', color:'white', fontSize:'calc(13px + 0.5vw)'}}>How to use PeerBox</p>
      <p style={{display:'inline-block', fontSize:'calc(12px + 0.1vw)',lineHeight:'200%', justifyContent:'center', color:'white'}}>Join rooms or create them. You decide how private the room should be - by simply choosing an appropriate name. If you entered a random batch of 64 letters, you can be sure noone will enter, unless you tell them the secret. On the other hand, a room named for example 'Kitties' might easily get discovered by someone else or there already are some people connected. Whenever two or more clients enter the same room name and press 'Join Room', they will be connected.</p>
      <span style={{display:'flex', color:'white', fontSize:'calc(12px + 0.2vw)', margin:'1vw'}}>Everyone in the room works as a server and will re-share everything with newcomers.</span>
      <span style={{display:'flex', color:'white', fontSize:'calc(12px + 0.2vw)', margin:'1vw'}}>Declined posts are being deleted permanently.</span>
      <span style={{display:'flex', color:'white', fontSize:'calc(12px + 0.2vw)', margin:'1vw'}}>Every peer in the room can only vote 1, 0, or -1 on each post. Like you're propably used to from other places.</span>
      <span style={{display:'flex', color:'white', fontSize:'calc(12px + 0.2vw)', margin:'1vw'}}>You can only post in the room when there's someone else. However, every browser tab counts as a new instance - another peer. By opening two tabs, you can prepare content to be shared, even if noone else hasn't joined yet.</span>
      <span style={{display:'flex', color:'white', fontSize:'calc(12px + 0.2vw)', margin:'1vw'}}>Supported file types are: Images(png, jpg, gif**), Video(mp4,m4v), Audio(mp3,mpga), Text(txt). More coming soon. </span>
      <a style={{display:'flex', marginTop:'2em'}} href='https://peerbox.cc/about'> About PeerBox</a>

    </div>
    </div>
      </>
    )
    }
    export default Howto
